
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { ElConfigProvider } from "element-plus";
import fr from "element-plus/lib/locale/lang/fr";
import ApiService from "@/core/services/ApiService";

import Amiri from "raw-loader!@/assets/fonts/Amiri.txt";
import pt_bold_heading from "raw-loader!@/assets/fonts/pt_bold_heading.txt";
import noto from "raw-loader!@/assets/fonts/NotoSansArabic-Bold.txt";
import amiri_bold from "raw-loader!@/assets/fonts/Amiri-Bold.txt";

import JsPDF from "jspdf";
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    console.log(store.getters.serverConfigUrl);
    ApiService.setHeaderBuilding(store.getters.serverConfigUrl.dbName);

    if (store.getters.serverConfigUrl?.base_url) {
      ApiService.setBaseUrl(store.getters.serverConfigUrl.base_url);
    } else ApiService.setBaseUrl(process.env.VUE_APP_API_URL);
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });

    let callAddFont: any = function (this: any) {
      this.addFileToVFS("Amiri-normal.ttf", Amiri);
      this.addFont("Amiri-normal.ttf", "Amiri", "normal");
      this.addFileToVFS("pt_bold_heading.ttf", pt_bold_heading);
      this.addFont("pt_bold_heading.ttf", "PT Bold Heading", "Bold");
      this.addFileToVFS("NotoSansArabic-Bold.ttf", noto);
      this.addFont("NotoSansArabic-Bold.ttf", "Noto Sans Arabic", "Bold");
      this.addFileToVFS("amiri_bold.ttf", amiri_bold);
      this.addFont("amiri_bold.ttf", "Amiri", "Bold");
    };
    JsPDF.API.events.push(["addFonts", callAddFont]);

    return {
      locale: fr,
    };
  },
});
